.wrapper-landing-page{
  padding: 7rem 0;
}

.navbar-landigpage {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 32px;
  @include media-breakpoint-down(md){
    padding-top: 20px;
    padding-bottom: 0;
  }

  a {
    text-decoration: none;
    color: #18568B;
  }

  li {
    margin-left: 3rem;
    @include media-breakpoint-down(md){
      margin-left: 1rem;
    }
  }
  /* padding-bottom: 20px; */
  // .nav-item
  .navbar-nav .nav-item {
    color: $pinion-primary !important;
    position: relative;
    margin-bottom: 5px;

    &.nav-button {
      .nav-subitem {
        position: absolute;
        display: none;
        white-space: nowrap;
        transform: translateX(-25%);
        a {
          color: $pinion-primary;
          font-weight: 400;
          &:hover {
            text-decoration: none;
            background-color: #f2f2f2;
          }
        }
      }
      &:hover .nav-subitem {
        display: block;
        top: 90%;
      }
    }

    &:hover {
      font-weight: 600;
    }

    &:not(.nav-button) {
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background-color:$pinion-primary;
        height: 3px;
        border-radius: 3px;
        width: 0px;
        min-width: 0px;
        transition: width 200ms linear, min-width 200ms linear;
      }
      &:hover,
      &.active {
        &:after {
          width: 50%;
          min-width: 30px;
        }
      }
    }
  }
}

.modal-drawer {
  max-width: 100%;
  height: 55%;
  margin: 0;

  .drawer-header {
    display: flex;
    justify-content: space-between;
  }

  .modal-content {
    border-radius: 0;
    height: 100%;
    padding: 6px 0;
  }

  ul {
    list-style: none;
    padding: 0 18px;
    color: #305574;
    font-size: 16px;
    font-weight: 500;

    li {
      padding: 10px 0;
      border-bottom: 1px solid rgba(228, 228, 228, 0.3);
    }
  }

  .btn-landing {
    padding-top: 8px;
    padding-bottom: 8px;
    border: none;
    border-radius: 10px;
  }
}

.button-landing {
  background-color: #1372BA;
  // color: #fff;
  border: none;
  border-radius: 5px;
  width: 100px;
}

.button-landing:hover {
  box-shadow: 0px 0px 15px -3px #4FAACE;
}

.active-navbar {
  a {
    font-weight: 600;
  }
}