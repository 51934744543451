/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
    width: auto !important;
    min-width: 320px !important;
    max-width: 420px;
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
}
.Toastify__toast-container--top-center {
}
.Toastify__toast-container--top-right {
}
.Toastify__toast-container--bottom-left {
}
.Toastify__toast-container--bottom-center {
}
.Toastify__toast-container--bottom-right {
}

/** Classes for the displayed toast **/
.Toastify__toast {
}
.Toastify__toast--rtl {
}
.Toastify__toast--dark {
}
.Toastify__toast--default {
}
.Toastify__toast--info {
    background-color: rgb(228, 228, 228) !important;
    opacity: 1;
    transition: opacity 150ms ease-in-out;

    &:hover {
        transform: translateY(0);
        opacity: 0.7;
    }
}
.Toastify__toast--success {
    background-color: #54B87C !important;
}
.Toastify__toast--error {
    background-color: #F86C6B !important;
}
.Toastify__toast--warning {
}
.Toastify__toast-body {
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
    color:rgb(0, 0, 0) !important;
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
}

/** Classes for the progress bar **/
.Toastify__progress-bar {
}
.Toastify__progress-bar--animated {
}
.Toastify__progress-bar--controlled {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar--default {
}
.Toastify__progress-bar--dark {
}