// font
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
$poppins: "Poppins";
// Here you can add other styles
html, body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  background-color: #fff;
  font-family: "Poppins";
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.login-form {
  border: 0;
  padding: 20px;
  border-radius: 20px;

  form {
    width: 100%;

    .login-submit {
      width: 100%;
      padding: 10px 0;
      color: #fff;
      background-color: #372974;
      margin-top: 20px;
      @include media-breakpoint-down(sm){
        margin-top: 15px;
      }
    }

    .input-form {
      .form-group{
        .form-control:focus {
          box-shadow: 0 0 0 0.1rem rgb(55, 41, 116);
          border: 1px solid $pinion-primary;
        }
      }
    }

    .forgot-password {
      display: flex;
      justify-content: flex-end;

      .link,
      link:hover {
        color: #2b3c5e;
        text-decoration: none;
      }
    }

    input {
      border: 1px solid #BDBDBD;
      border-radius: 8px;
      background-color: white;
      padding-left: 35px;
    }

    input::placeholder {
      color: #BDBDBD;
    }
  }
}

.icon-inside-left {
  position: absolute;
  left: 13px;
  top: 12px;
  @include media-breakpoint-down(sm){
    top: 41px;
  }
}

.icon-see-password {
  position: absolute;
  right: 14px;
  top: 10px;
  font-size: 14px;
  @include media-breakpoint-down(sm){
    top: 41px;
  }
}

.icon-see-password:hover {
  cursor: pointer;
}

.register-form {
  .register-nav {
    margin-bottom: 15px;
    text-align: center;
    .dot {
      color: black;
      cursor: pointer;
      font-size: 36px;
      line-height: 1;
      margin: 0 15px;
      opacity: 0.4;
      text-shadow: none;
      transition: opacity 1s ease, text-shadow 1s ease;
      will-change: opacity, text-shadow;

      &.active {
        color: var(--blue);
        opacity: 1;
        text-shadow: 0 0px 8px;
      }
    }
  }
}
.round-100 {
  width: 35px;
  border-radius: 100%;
  height: 35px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 0.2px solid #333;
}

.projects-filter {
  position: fixed;
  top: 80px;
  left: 0px;

  @include media-breakpoint-up(lg){
    top: 110px;
    right: 20px;
  }

  @include media-breakpoint-up(xl){
    top: 100px;
    right: 150px;
  }
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 5rem !important;
  margin: 2px;
}

.verification-process-form {
  .upload-file-button {
    position: absolute;
    width: 200px;
    height: 200px;
    color: #dbe2f5;
    &.filled {
      color: transparent;
      background-color: transparent;
    }
    &:hover {
      color: #030303;
      background-color: whitesmoke;
      opacity: 0.5;
    }
  }
}

.profile-tabs-item {
  border: 0;
  margin: 0 5px;
  color: rgba(51, 51, 51, 0.781) !important;
  &:hover {
    border: 0;
    background: $primary !important;
    color: white !important;
    border-radius: 10px;
  }
  &.active {
    border: 0;
    background: $primary !important;
    color: white !important;
    border-radius: 10px;
  }
}

.navbar-logo {
  width: 120px;
}

.sidebar-fixed .sidebar {
  width: 250px;
  background-color: #fff;

  .sidebar-nav {
    margin-top: 30px;
    width: 250px;

    .nav {
      width: 220px;
    }
  }

  .nav-link {
    color: $pinion-primary;
    padding: 0.8rem 2.3rem;
    margin-bottom: 10px;

    .nav-icon {
      color: $pinion-primary;
    }
  }

  .nav-link:hover,
  .nav-link.active {
    color: $pinion-primary;
    font-weight: 600;
    background: #edf0f2;
    border-radius: 0 8px 8px 0;

    .nav-icon {
      color: $pinion-primary;
    }
  }

  .nav-dropdown-toggle::before {
    width: 10px;
    height: 10px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  }

  .nav-dropdown-toggle:hover::before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%232B3C5E' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  }

  .nav-dropdown.open {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 0 8px 8px 0;
  }
}