.modal .modal-dialog.bottom,
.modal .modal-dialog.right,
.modal .modal-dialog.left {
    position: fixed;
    margin: auto;
    width: 100%;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.modal-dialog.bottom .modal-content,
.modal-dialog.right .modal-content,
.modal-dialog.left .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal-dialog.bottom .modal-body,
.modal-dialog.right .modal-body,
.modal-dialog.left .modal-body {
  padding: 0px 15px 30px 15px;
}

/*bottom*/
.modal.fade .modal-dialog.bottom {
  bottom: -400px;
  -webkit-transition: opacity 0.15s linear, bottom 0.15s ease-out;
  -moz-transition: opacity 0.15s linear, bottom 0.15s ease-out;
  -o-transition: opacity 0.15s linear, bottom 0.15s ease-out;
  transition: opacity 0.15s linear, bottom 0.15s ease-out;
}

.modal.fade.show .modal-dialog.bottom {
  bottom: 0;
}

@media (min-width: 576px){
  .modal-dialog.bottom {
    max-width: 100%;
  }
}

/*left*/
.modal.fade .modal-dialog.left {
  left: -400px;
  -webkit-transition: opacity 0.15s linear, left 0.15s ease-out;
  -moz-transition: opacity 0.15s linear, left 0.15s ease-out;
  -o-transition: opacity 0.15s linear, left 0.15s ease-out;
  transition: opacity 0.15s linear, left 0.15s ease-out;
}

.modal.fade.show .modal-dialog.left {
  left: 0;
}

/*right*/
.modal.fade .modal-dialog.right {
  right: -400px;
  -webkit-transition: opacity 0.15s linear, right 0.15s ease-out;
  -moz-transition: opacity 0.15s linear, right 0.15s ease-out;
  -o-transition: opacity 0.15s linear, right 0.15s ease-out;
  transition: opacity 0.15s linear, right 0.15s ease-out;
}

.modal.fade.show .modal-dialog.right {
  right: 0;
}

.modal .modal-dialog.bottom-small {
  position: fixed;
  margin: auto;
  width: 100%;
  height: 40%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal-dialog.bottom-small .modal-content {
  height: 100%;
  overflow-y: auto;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.modal-dialog.bottom-small .modal-body {
  padding: 0px 15px 30px 15px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

/*bottom-small*/
.modal.fade .modal-dialog.bottom-small {
  bottom: -400px;
  -webkit-transition: opacity 0.15s linear, bottom 0.15s ease-out;
  -moz-transition: opacity 0.15s linear, bottom 0.15s ease-out;
  -o-transition: opacity 0.15s linear, bottom 0.15s ease-out;
  transition: opacity 0.15s linear, bottom 0.15s ease-out;
}

.modal.fade.show .modal-dialog.bottom-small {
  bottom: 0;
}

@media (min-width: 576px){
  .modal-dialog.bottom-small {
    max-width: 100%;
  }
}